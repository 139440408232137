<template>
  <v-footer dark color="#17112c">
    <v-container class="py-1">
      <div class="d-flex align-center justify-space-between">
        <v-avatar width="80" height="30" tile>
          <img src="@/assets/images/logo.svg" alt="" />
        </v-avatar>

        <v-btn
          class="black--text"
          elevation="0"
          small
          color="white"
        >
          Contato
        </v-btn>
      </div>
    </v-container>
  </v-footer>
</template>
<script>
export default {
  name: "Footer",
  data: () => ({})
};
</script>
<style lang="scss"></style>
